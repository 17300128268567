.ant-modal-content {
    border-radius: 20px !important;
    padding: 40px 40px !important;
}

.fix-input {
    color: #D2538C;
    border-top: 1px solid rgba(210, 83, 140, 0.5);
    border-left: 1px solid rgba(210, 83, 140, 0.5);
    border-bottom: 1px solid rgba(210, 83, 140, 0.5);
    border-radius: 2.4em 0 0 2.4em;
    padding: 16px 20px;
}

.fix-input-focus {
    color: #D2538C;
    border-top: 1px solid #D2538C;
    border-left: 1px solid #D2538C;
    border-bottom: 1px solid #D2538C;
    border-radius: 2.4em 0 0 2.4em;
    padding: 16px 20px;
}

.react-datepicker-wrapper input {
    padding: 16px 45px !important;
}

.inactive-thumb {
    height: 10px;
    width: 10px;
    background-color: #000;
}

.otp-input {
    border: '1px solid rgba(210, 83, 140, 0.5)';
    border-radius: 2.4em;
    padding: 16px 20px;
    margin-right: 20px;
    color: '#D2538C';
    width: 6em;
    margin-bottom: 25px
}

// .ant-modal-title {
//     color: #333 !important;
//     font-weight: 600 !important;
//     font-size: 24px !important;
// }